#welcome-section {
  .sign-up-page {
    .topics-signup-step {
      h3 {
        margin: 54px 6px 30px;
        color: var(--ion-color-dark);
        text-align: left;
        font-weight: normal;
      }
    }
  }
}

@import "responsive";
