ion-header.welcome-header {
    --color: white;
    margin: 0 auto;
    background: white;
    ion-toolbar {
        padding: 20px 0 !important;
        max-width: 920px;
        margin: 0 auto;
        --min-height: 120px;
        --border-color: transparent;
        .logo {
            display: block;
            position: relative;
            max-width: 20vw;
            width: 100%;
            cursor: pointer;
            min-height: 38px;
        }
        .swipe-logo {
            max-width: 20vw;
            max-height: 105px;
        }
        ion-button {
            font-weight: bold;
            --padding-start: 20px;
            --padding-end: 20px;
            &.btn-sign-up {
                --color: var(--ion-color-primary);
                --background: w;
            }
        }
    }
}
@import "responsive";
