#welcome-section {
  .sign-up-page {
    .topics-signup-step {

      @media screen and (min-width: 600px) {
        h3 {
          margin: 12px 6px 30px;
        }
      }
    }
  }
}
