#instagram-connection {
  .why-connect {
    background-color: #eee;
    border-radius: 5px;
    padding: 0.725em 1.125em;
  }

  .why-connect__text {
    color: #444;
    font-size: 0.875em;
  }

  .sign-in-content {
    text-align: center;
    .connection-logos {
      display: flex;
      justify-content: center;
      margin: 10vw 0;

      > img, > div {
        width: 10vw;
        height: 10vw;
      }

      ion-icon {
        font-size: 10vw;
        margin: 0 2vw;
        color: var(--ion-color-medium)
      }
    }

    .facebook-login {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-color: transparent;
      background-image: url('/assets/facebook-login-button.png');
      display: block;
      margin: 0 auto;
      width: 300px;
      height: 64px;
    }
  }
  .publish-schedule {
    margin: 10px 0 0 ;
    ion-icon {
      font-size: 30px;
      color: var(--ion-color-medium);
      margin-bottom: -5px;
    }
    .minutes-input-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 16px;
      ion-input {
        flex-grow: 0;
        min-width: 32px;
        border-bottom: 1px solid var(--ion-color-light-contrast);
        text-align: center;
        --padding-end: 0;
      }
    }
  }
  .connection-toggle {
    --padding-start: 0;
  }
  h4 {
    color: var(--ion-color-medium);
  }
  .bottom-sticky-section {
    padding: 10px;
    margin-bottom: var(--ion-safe-area-bottom);
  }
}

.enable-tracking__steps {
  color: #444;
  text-align: left;
}

.enable-tracking__buttons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.enable-tracking__button {
  flex: 1;
}

@import "responsive";
